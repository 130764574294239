import { AdminService } from '../../services/admin';
import { UserService } from '../../services/user';
export default async function loader(context) {
  const accessToken = localStorage.getItem('accessToken');
  console.log('🚀 ~ file: loader.js:4 ~ loader ~ accessToken:', accessToken);
  if (accessToken) {
  } else {
    throw new Response('Bạn vẫn chưa đăng nhập!', { status: 404 });
  }

  const response = await AdminService.getMe();
  return response.data;
}
