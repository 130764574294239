import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../../../../utils/hooks';
import {SongService} from '../../../../../services/song';
import Song from './Song';

export default function AddMusic({onAddSong, listSong}) {
  // const []
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(true);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const searchMusic = (name)=>{
    return SongService.searchSongs(name).then(res=>res.data.songs)
  }
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        searchMusic(debouncedSearchTerm).then((results) => {
          console.log("🚀 ~ file: AddMusic.jsx:20 ~ searchMusic ~ results:", results)
          setIsSearching(false);
          setResults(results);
        });
      } else {
        setResults([]);
        setIsSearching(false);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  return <div>
    <input className="outline-none focus:ring ring-blue-300 p-2 border rounded-lg" type="text" onChange={(e)=>setSearchTerm(e.target.value)} placeholder='Tim kiem bai hat'/>
    <div className="max-h-56 overflow-y-scroll">
      {isSearching ? [0,1,2].map((i)=><SekeletonSearching key={i}/>) : results.map(song => <Song
      checked={
        listSong.findIndex((item)=>item._id === song._id) !== -1
      }
      onChange={(e)=>{
        const checked = e.target.checked;
        if(checked){
          onAddSong(song)
        }else{
          onAddSong(song, true)
        }
      }} key={song._id} {...song}/>)}
    </div>
    <p>Bai hat da chon</p>
    {
      listSong && <div className="max-h-56 overflow-y-scroll">
      { listSong.map(song => <Song key={song._id} {...song} onChange={(e)=>{
        onAddSong(song, true)
      }} checked={true}/>)}
    </div>
    }
  </div>;
}

function SekeletonSearching(){
  return <div className="flex my-2 gap-2">
    <div className="skeleton-box h-16 w-16 rounded-lg bg-gray-300"></div>
    <div className="flex flex-col gap-1">
      <div className="skeleton-box w-48  h-4 rounded-lg"></div>
      <div className="skeleton-box w-16  h-4 rounded-lg"></div>
      <div className="skeleton-box w-8  h-4 rounded-lg"></div>
    </div>
  </div>
}