import React from 'react'

export default function Playlist({title, description, createdAt, listSongs, _id, thumbnail}) {
    const PF = process.env.REACT_APP_SERVER_URL;

  return (
    <div className='flex flex-col'>
      <div className="w-[150px] h-[200px] rounded-lg shadow-xl" style={{
        backgroundImage: `url(${PF}/images/${thumbnail})`,
        backgroundSize: "150px 200px",
        backgroundRepeat: "no-repeat"
      }}></div>
      <p>{title}</p>
    </div>
  )
}
