import React from 'react';
import { TbHome, TbPlaylist } from 'react-icons/tb';
import { Link } from 'react-router-dom';
function ManagePlaylist({ icon, to, name }) {
  return (
    <Link to={to} className="flex gap-2 p-2 hover:bg-blue-300 rounded-lg">
      {icon}
      <p className="font-bold">{name}</p>
    </Link>
  );
}

export default function NavigationBar() {
  return (
    <div className="h-full flex flex-col justify-between items-center ">
      <div className="flex flex-col gap-y-2">
        <p className="text-3xl font-bold ">SongSphere Admin</p>
        <div className="flex flex-col space-y-2">
          <ManagePlaylist icon={<TbHome size="26" />} to="/" name="Trang chủ" />
          <ManagePlaylist
            icon={<TbPlaylist size="26" />}
            to="/playlist"
            name="Quản lý danh sách phát"
          />
        </div>
      </div>
      <button className="p-2 text-xl font-bold text-white rounded-lg bg-blue-300">
        Đăng xuất
      </button>
    </div>
  );
}
