import axiosClient from '../axiosClient';

export const AuthService = {
  login: (data) => {
    return axiosClient.post('/auth/login', {
      ...data,
      role: 'admin',
    });
  },
};
