import React from 'react';
import GroupPlaylist from './GroupPlaylist';
import { useState } from 'react';
import AddGroup from './AddGroup';
import { useSelector } from 'react-redux';
export default function Playlist() {
  const group = useSelector((state) => state.home.group);
  return (
    <div>
      <div className="overflow-y-auto">
        {group.length === 0 ? (
          <div>
            <p className="text-2xl font-bold">
              Ban chua co playlist trang chu nao
            </p>
          </div>
        ) : (
          <div>
            <p>Danh sach nhom</p>
            {group.map((item) => (
              <GroupPlaylist {...item} />
            ))}
          </div>
        )}
      </div>
      <AddGroup />
    </div>
  );
}
