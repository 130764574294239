import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  group: [],
};

export const homeSlice = createSlice({
  name: 'Home slice',
  initialState,
  reducers: {
    setGroup: (state, action) => {
      return {
        ...state,
        group: action.payload,
      };
    },
    addGroup: (state, action) => {
      return {
        ...state,
        group: [...state.group, action.payload],
      };
    },
    addPlaylistToGroup: (state, action) => {
      return {
        ...state,
        group: state.group.map((group) => {
          if (group._id === action.payload.groupId) {
            return {
              ...group,
              playlists: [...group.playlists, action.payload.playlist],
            };
          }
          return group;
        }),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addGroup, setGroup, addPlaylistToGroup } = homeSlice.actions;

export default homeSlice.reducer;
