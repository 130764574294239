import React, { useEffect, useState } from 'react';
import Modal from '../../../../../Components/Modal';
import AddPlaylist from './AddPlaylist';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { AdminService } from '../../../../../services/admin';
import { useDispatch } from 'react-redux';
import { addPlaylistToGroup } from '../../../../../app/slices';
export default function AddPlaylistToGroup({groupId}) {
  const methods = useForm();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = async () => {
    console.log(methods.getValues());
    const listSong = methods.getValues("listSong");
    const file = methods.getValues("file");
    if(!file){
      methods.setError('file',{
        message: "Ban chua them thumbnail cho playlist"
      })
      return
    }
    if(!listSong ||listSong.length === 0){
      methods.setError('listSong',{
        message: "Ban chua them bai hat nao vao playlist"
      })
      return
    }
    const data = methods.getValues();
    console.log(data);
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("fileName", data.fileName);
    formData.append("listSong", data.listSong);
    formData.append("file", data.file);

    const response = await AdminService.addPlaylistToGroup(groupId, formData)
    const {playlist} = response.data
    dispatch(addPlaylistToGroup({
      playlist,
      groupId
    }));
    setOpen(false)
  };

  return (
    <div>
      <button
        className="bg-blue-500 rounded-full p-1 flex items-center justify-center text-white "
      title="Them danh sach phat"
        onClick={() => setOpen(true)}
      >
        <FaPlus size="12"/>
      </button>
      <FormProvider {...methods}>
        <form >
        <Modal
          child={<AddPlaylist/>}
          isOpen={isOpen}

          onClose={() => {
            methods.reset();
            setOpen(false)
          }}
          title={'Them danh sach phat'}
          SubmitBtn={
            <button
              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:b g-green-500 sm:ml-3 sm:w-auto"
              type="submit"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Add
            </button>
          }
        />
      </form>
      </FormProvider>
    </div>
  );
}
