import axiosClient from '../axiosClient';

export const AdminService = {
  addGroup: (data) => {
    return axiosClient.post('/admin/group', data);
  },
  getMe: () => {
    return axiosClient.get('/admin');
  },
  addPlaylistToGroup: (_id, data) => {
    return axiosClient.post(`/admin/group/${_id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  editGroup: (data) => {},
  editPlaylist: (data) => {},
  deleteGroup: (data) => {},
  deletePlaylist: (data) => {},
};
