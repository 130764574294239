import axiosClient from '../axiosClient';

export const SongService = {
  searchSongs: async (title) => {
    return await axiosClient.get('/songs/search', {
      params: {
        title,
        take: 100,
      },
    });
  },
};
