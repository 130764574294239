import React from 'react'
import { useFormContext } from 'react-hook-form'

export default function Add() {
  const {register, formState: {errors}} = useFormContext();
  return (
    <div className="flex flex-col space-y-1">
      <div>
        <label htmlFor="name">
          Ten nhom
          <span className="text-red-500">*</span>
          {
            errors.name ? <span className="text-red-500">Trường này bắt buộc nhập</span> : ""
          }
        </label>
        <input {...register('name', {
          required: true
        })} type="text" className=" outline-none focus:ring ring-blue-300 border border-gray-300 rounded-lg p-2 w-full"/>
      </div>
      <div>
        <label htmlFor="description">
          Mo ta
         
        </label>
        <textarea {...register('description')} type="text"  rows={3} className="resize-none outline-none focus:ring ring-blue-300 border border-gray-300 rounded-lg p-2 w-full"/>
      </div>
    </div>
  )
}
