import React from 'react';
import Playlist from './Playlist';
import AddPlaylistToGroup from "../AddPlaylistToGroup"
export default function GroupPlaylist(props) {
  return (
    <div>
      <div>
        <div className="flex gap-2">
          <span className="text-xl font-bold ">{props.name}</span> -{' '}
          {new Date(props.createdAt).toLocaleDateString('vi-VI')}
          <AddPlaylistToGroup groupId={props._id}/>
        </div>
        <p className="text-sm">{props.description}</p>
      </div>
      <div>
        <p className="text-sm font-bold">Danh sách phát</p>
       <div className="flex gap-2 overflow-x-auto">
         {
          props.playlists && props.playlists.map((playlist)=> <Playlist {...playlist}/>)
        }
       </div>
      </div>
      {/* <AddPlaylistToGroup /> */}
    </div>
  );
}
