import React from 'react';
import { useForm } from 'react-hook-form';
import { AuthService } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import { ToastService } from '../utils/Toast';
import { ToastContainer, toast } from 'react-toastify';

export default function Login() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try{
        const response = await AuthService.login(data);
        const {data: {accessToken}} = response;
        localStorage.setItem('accessToken', accessToken);
        toast("Đăng nhập thành công", {
            type: "success",
            onClose: ()=>{
                navigate("/")
            }
        })
    }catch(error){
        // setError("total", {message: error.response.data.message})
        console.log(error);
        ToastService.error(error.response.data.mesage)
    }
  };
  
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-1/2 h-1/2 shadow-xl rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold">Đăng nhập</h1>
            <p className="text-sm">Welcome to ...</p>
            <div className="w-1/2 ">
              <label htmlFor="username" className="m-2">
                Tên đăng nhập
                <span className="text-red-500">*</span>
              </label>
              {errors.username && (
                <span className="text-red-500">{
                    errors.username.message ? errors.username.message : "Trường này bắt buộc nhập"
                }</span>
              )}
              <input
                className="border-2 border-gray-400 rounded-lg w-full h-10 p-2 m-2"
                type="text"
                placeholder="Tên đăng nhập"
                {...register('username', { required: true })}
              />

              <label htmlFor="username" className="m-2">
                Mật khẩu
                <span className="text-red-500">*</span>
              </label>
              {errors.password && (
                <span className="text-red-500">
                    {
                         errors.password.message ? errors.password.message : "Trường này bắt buộc nhập"
                    }
                </span>
              )}
              <input
                className="border-2 border-gray-400 rounded-lg w-full h-10 p-2 m-2"
                type="password"
                placeholder="Mật khẩu"
                {...register('password', { required: true })}
              />

              <div className="w-full text-center">
                <button
                  className="w-1/3 mx-auto border-2 bg-blue-400 text-xl font-bold text-white rounded-lg py-2 hover:bg-blue-300 hover:scale-105"
                  type="submit"
                >
                  Đăng nhập
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
