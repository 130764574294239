import React, { useEffect } from 'react'
import NavigationBar from "./NavigationBar"
import { Outlet, useLoaderData } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import {setGroup } from "../../app/slices/index"
export default function Home() {
  const data = useLoaderData();
  const dispatch = useDispatch();
  useEffect(()=>{
    console.log(data);
    dispatch(setGroup(data.group))
  },[data])
  return (
    <div className="flex">
        <div className="flex-1 h-screen shadow-2xl p-4 ">
            <NavigationBar/>
        </div>
        <div className="h-screen flex-[5] overflow-y-auto p-4">
            <Outlet/>
        </div>
    </div>
  )
}
