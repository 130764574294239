import React, { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { ToastService } from '../../utils/Toast';
import { toast } from 'react-toastify';

export default function Error() {
  const error = useRouteError();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (error.status === 404) {
  //    toast(error.data, {
  //     type: "error",
  //     onClose: ()=>navigate("/login")
  //    })
  //   }
  //   if(error.response.status === 400){
  //     toast('Hết phiên đăng nhập! Vui lòng đăng nhập lại', {
  //       type: "error",
  //       onClose: ()=> navigate("/login")
  //     })
  //   }
  // }, []);
  useEffect(() => {
    console.log(error.status);
    if(error.status === 404 || error.status === 401 || error.status === 400){

      // console.log(error.status.startWith(4))
      // if (error.status.startsWith('4')) {
        toast(error.data);
        // console.log(error.data);
        navigate('/login');
      }
    // }
  }, []);

  return <div>Error</div>;
}
