import React, { useState } from 'react';
import Modal from '../../../../../Components/Modal';
import Add from './Add';
import { FormProvider, useForm } from 'react-hook-form';
import { AdminService } from '../../../../../services/admin';
import { useDispatch } from 'react-redux';
import { addGroup } from '../../../../../app/slices';
export default function AddGroup() {
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();
  const dispatch = useDispatch();
  const onSubmit = async (data)=>{
    const response = await AdminService.addGroup(data);
    dispatch(addGroup(response.data.groupPlaylist))
    setIsOpen(false)
  }
  return (
    <div>
      {/* button with text "Them nhom" */}
      <button
        onClick={() => setIsOpen(true)}
        className="
        p-2 text-xl font-bold text-white rounded-lg bg-blue-300
      "
      >
        Them nhom
      </button>
      {/* modal */}
      <FormProvider {...methods}>
        <Modal
        title="Them nhom"
        SubmitBtn={
          <button onClick={methods.handleSubmit(onSubmit)} className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:b g-green-500 sm:ml-3 sm:w-auto">
            Them
          </button>
        }
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        child={<Add />}
      />
      </FormProvider>
    </div>
  );
}
