import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { TbReplace } from 'react-icons/tb';
import Modal from '../../../../../Components/Modal';
import AddMusic from './AddMusic';
import Song from './Song';
import { useEffect } from 'react';
export default function AddPlaylist() {
  const [isOpen, setOpen] = useState(false);
  const [listSong, setListSong] = useState([]);
  const generateFileName = (name) => {
    return (
      Date.now() +
      Math.floor(Math.random() * (999 - 100 + 1) + 100) +
      '.' +
      name.split('.').pop()
    );
  };
  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    clearErrors
  } = useFormContext();
  const [url, setUrl] = useState('');
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const tempUrl = URL.createObjectURL(file);
      setUrl(tempUrl);
      register('file', {
        value: file,
      });
      register('fileName', {
        value: generateFileName(file.name),
      });
      clearErrors("file")
    }
  };
  useEffect(()=>{
    const temp = listSong.map(i=>i._id)
    if(temp){
      clearErrors('listSong')
    }
      setValue('listSong', temp)
  },[listSong])
  return (
    <div>
      <div className="flex flex-col">
        <label htmlFor="name" className="text-sm">
          Tieu de
          <span className="text-red-500">*</span>
          {errors.name ? (
            <span className="text-red-500">Trường này bắt buộc nhập</span>
          ) : (
            ''
          )}
        </label>
        <input
          id="title"
          placeholder="Ten"
          className="border outline-none focus:ring-1 rounded-lg p-2"
          {...register('title', {
            required: true,
          })}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="description" className="text-sm">
          Mo ta
        </label>
        <textarea
        rows="3"
          id="description"
          placeholder="Ten"
          className="border outline-none focus:ring-1 rounded-lg p-2 resize-none"
          {...register('description', {
            required: false,
          })}
        />
      </div>
      <div className="flex flex-col my-2">
        <label htmlFor="thumbnail" className="text-sm">
          Thumbnail
                    <span className="text-red-500">*</span>

           {errors.file ? (
            <span className="text-red-500">{errors.file.message}</span>
          ) : (
            ''
          )}
        </label>
        <div
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize: '80px 80px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          className={`w-20 h-20 rounded-lg border-dashed relative group ${
            url.length === 0 ? 'border-2' : 'border'
          } border-blue-300`}
        >
          {url.length === 0 ? (
            <label
              htmlFor="thumbnail"
              className="w-full h-full flex items-center justify-center cursor-pointer text-blue-300"
            >
              <FaPlus size="16" />
            </label>
          ) : (
            <label
              htmlFor="thumbnail"
              className="hidden group-hover:flex items-center justify-center text-white absolute w-6 h-6  bg-blue-200 rounded-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            >
              <TbReplace size="20" />
            </label>
          )}
        </div>
        <input
          id="thumbnail"
          placeholder="Ten"
          hidden
          type="file"
          accept="image/*"
          onChange={handleChangeFile}
        />
      </div>
      <div className="flex flex-col my-2">
        <label htmlFor="">
          Danh sach nhac <span>({listSong.length} bai hat)</span>
          {
            errors.listSong ? <span className="text-red-500">{errors.listSong.message}</span> : ''
          }
        </label>
        <div className="max-h-56 overflow-y-auto">
          {listSong.map((song) => (
            <Song
              checked={true}
              onChange={() => {
                setListSong(listSong.filter((item) => item._id !== song._id));
              }}
              key={song._id}
              {...song}
            />
          ))}
        </div>
        <button
          className="p-2 rounded-lg font-semibold text-white bg-blue-500"
          onClick={() => setOpen(true)}
        >
          Them nhac
        </button>
      </div>
      <Modal
        title={'Them nhac'}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        child={
          <AddMusic
            listSong={listSong}
            onAddSong={(song, deleted) => {
              if (!deleted) {
                setListSong([...listSong, song]);
              } else {
                setListSong(listSong.filter((item) => item._id !== song._id));
              }
            }}
          />
        }
        hideCancle
        SubmitBtn={
          <button
            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:b g-green-500 sm:ml-3 sm:w-auto"
            onClick={() => {
              setOpen(false);
            }}
          >
            Them
          </button>
        }
      />
    </div>
  );
}
