import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Dashboard from './pages/Home/children/Dashboard';
import loader from './pages/Home/loader';
import Error from './pages/Home/Error';
import Playlist from './pages/Home/children/Playlist';
import { Provider } from 'react-redux';
import { store } from './app/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    loader: ({ params, request, context }) => loader(request),
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/playlist',
        index: true,
        element: <Playlist />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
root.render(
  <React.Fragment>
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer
        autoClose={1000}
        closeButton={true}
        closeOnClick={true}
        limit={1}
      />
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
