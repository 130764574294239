import { toast } from 'react-toastify';

export const ToastService = {
  success: (message) => {
    return toast.success(message);
  },
  error: (message) => {
    return toast.error(message);
  },
  warning: (message) => {
    return toast.warning(message);
  },
};
