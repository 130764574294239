import React from 'react';

export default function Song({ thumbnail, name, artist, duration, onChange, checked }) {
  return (
    <div className="flex gap-2 my-1">
      <div
        style={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: '64px 64px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className={`w-16 h-16 rounded-lg flex-1`}
      ></div>
      <div className='flex-[5]'>
        <p className="text-sm font-bold line-clamp-1">{name}</p>
        <p className="text-sm">{artist.name}</p>
        <p className="text-sm">{duration}</p>
      </div>
      <input checked={checked} onChange={onChange} type="checkbox" className="ml-auto"/>
    </div>
  );
}
